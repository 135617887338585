function Footer() {
    const liveFreeFlagStyle = {
        width: "50%"
    };

    const designlyRefStyle = {
        marginLeft: "4px"
    }

    return (
        <footer className="footer text-center">
            <div className="container">
                <div className="row">

                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <img className="loadImg" src="/images/image-loader.svg" alt="Live Free"
                            data-src="/svg/live-free-flag.svg" style={liveFreeFlagStyle} />
                    </div>

                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h4 className="text-uppercase mb-4">Around the Web</h4>
                        <a className="btn btn-outline-light btn-social mx-1"
                            href="https://facebook.com/simplycleanmichell" target="_blank" rel="noreferrer"><i
                                className="fab fa-fw fa-facebook-f"></i></a>
                        <a className="btn btn-outline-light btn-social mx-1" href="#!"><i className="fab fa-fw fa-twitter"></i></a>
                        <a className="btn btn-outline-light btn-social mx-1" href="#!"><i
                            className="fab fa-fw fa-linkedin-in"></i></a>
                        <a className="btn btn-outline-light btn-social mx-1" href="#!"><i className="fab fa-fw fa-dribbble"></i></a>
                    </div>

                    <div className="col-lg-4">
                        <h4 className="text-uppercase mb-4">The Author</h4>
                        <p className="lead mb-0">
                            This site was lovingly designed by:
                            <a href="https://designly.biz" target="_blank" rel="noreferrer" style={designlyRefStyle}>Designly</a>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;