import { ReactComponent as LoaderSvg } from '../svg/loading.svg';

function Loader() {
    return (
        <div id="loading-cont">
            <div><LoaderSvg /></div>
        </div>
    );
}

export default Loader;