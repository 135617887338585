function Portfolio() {
    return(
        <section className="page-section portfolio" id="services">
        <div className="container">
            
            <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Services Provided</h2>
            
            <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                <div className="divider-custom-line"></div>
            </div>
            
            <div className="row justify-content-center">
                
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#s-clean-surface">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid loadImg" src="/images/image-loader.svg" alt="Clean all Surfaces"
                            data-src="/images/cicons/clean-surface.webp" />
                        <p className="service-title">Clean all Surfaces &amp; Cabinets</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#s-trash">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid loadImg" src="/images/image-loader.svg" alt="Trash Removal"
                            data-src="/images/cicons/trash.webp" />
                        <p className="service-title">Trash Removal</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#s-vacuum">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid loadImg" src="/images/image-loader.svg" alt="Vacuum Carpets"
                            data-src="/images/cicons/vacuum.webp" />
                        <p className="service-title">Vacuum Carpets</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#s-linens">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid loadImg" src="/images/image-loader.svg"
                            alt="Change Linens &amp; Make Beds" data-src="/images/cicons/make-bed.webp" />
                        <p className="service-title">Change Linens and Make Beds</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#s-floors">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid loadImg" src="/images/image-loader.svg"
                            alt="Sweep &amp; Hand-Wash All Floors" data-src="/images/cicons/mop-floor.webp" />
                        <p className="service-title">Sweep &amp; Hand-Wash All Floors</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#s-dust">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid loadImg" src="/images/image-loader.svg" alt="Dust all Surfaces"
                            data-src="/images/cicons/dust.webp" />
                        <p className="service-title">Dust all Surfaces</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#s-sinks">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid loadImg" src="/images/image-loader.svg"
                            alt="Clean all Sinks &amp; Appliances" data-src="/images/cicons/clean-sink.webp" />
                        <p className="service-title">Clean all Sinks &amp; Appliances</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#s-bath">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid loadImg" src="/images/image-loader.svg"
                            alt="Clean all Toilets, Showers &amp; Tubs" data-src="/images/cicons/clean-bathroom.webp" />
                        <p className="service-title">Clean all Toilets, Showers &amp; Tubs</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-5">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#s-laundry">
                        <div
                            className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="portfolio-item-caption-content text-center text-white"><i
                                    className="fas fa-plus fa-3x"></i></div>
                        </div>
                        <img className="img-fluid loadImg" src="/images/image-loader.svg" alt="Laundry Services"
                            data-src="/images/cicons/laundry.webp" />
                        <p className="service-title">Laundry Services<sup>*</sup></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Portfolio;