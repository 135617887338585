function Modals() {
    return (
        <div>
            <div className="portfolio-modal modal fade" id="s-clean-surface" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                            aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">
                                            Clean all Surfaces &amp; Cabinets
                                        </h2>

                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                        
                                        <img className="img-fluid rounded mb-5 loadImg" src="/images/image-loader.svg"
                                            alt="Clean all Surfaces" data-src="/images/cicons/clean-surface.webp" />
                                        
                                        <p className="mb-4">
                                            We will clean all surfaces, cabinet faces, countertops, tables, etc.
                                            Eco-friendly cleaning chemicals available!
                                        </p>
                                        <button className="btn btn-primary" href="#s-clean-surface" data-bs-dismiss="modal">
                                            <i className="fas fa-times fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-modal modal fade" id="s-trash" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                            aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">
                                            Trash Removal
                                        </h2>

                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                        
                                        <img className="img-fluid rounded mb-5 loadImg" src="/images/image-loader.svg"
                                            alt="Trash Removal" data-src="/images/cicons/trash.webp" />
                                        
                                        <p className="mb-4">
                                            We will remove and dispose of refuse and recyclables.
                                        </p>
                                        <button className="btn btn-primary" href="#s-trash" data-bs-dismiss="modal">
                                            <i className="fas fa-times fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-modal modal fade" id="s-vacuum" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                            aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">
                                            Vacuum all Carpets
                                        </h2>

                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                        
                                        <img className="img-fluid rounded mb-5 loadImg" src="/images/image-loader.svg"
                                            alt="Vacuum Carpets" data-src="/images/cicons/vacuum.webp" />
                                        
                                        <p className="mb-4">
                                            We will vacuum all carpets, rugs and stairs.
                                        </p>
                                        <button className="btn btn-primary" href="#s-vacuum" data-bs-dismiss="modal">
                                            <i className="fas fa-times fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-modal modal fade" id="s-linens" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                            aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">
                                            Change Linens &amp; Make Beds
                                        </h2>

                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                        
                                        <img className="img-fluid rounded mb-5 loadImg" src="/images/image-loader.svg"
                                            alt="Change Linens &amp; Make Beds" data-src="/images/cicons/make-bed.webp" />
                                        
                                        <p className="mb-4">
                                            We will change all linens and make beds.
                                        </p>
                                        <button className="btn btn-primary" href="#s-linens" data-bs-dismiss="modal">
                                            <i className="fas fa-times fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-modal modal fade" id="s-floors" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                            aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">
                                            Sweep &amp; Hand-Wash all Floors
                                        </h2>

                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                        
                                        <img className="img-fluid rounded mb-5 loadImg" src="/images/image-loader.svg"
                                            alt="Sweep Floors" data-src="/images/cicons/mop-floor.webp" />
                                        
                                        <p className="mb-4">
                                            We will sweep and hand-wash all flooring surfaces.
                                        </p>
                                        <button className="btn btn-primary" href="#s-floors" data-bs-dismiss="modal">
                                            <i className="fas fa-times fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-modal modal fade" id="s-dust" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                            aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">
                                            Dust all Surfaces
                                        </h2>

                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                        
                                        <img className="img-fluid rounded mb-5 loadImg" src="/images/image-loader.svg"
                                            alt="Dust all Surfaces" data-src="/images/cicons/dust.webp" />
                                        
                                        <p className="mb-4">
                                            We will dust all surfaces, tops of fixtures, fans & book cases.
                                        </p>
                                        <button className="btn btn-primary" href="#s-dust" data-bs-dismiss="modal">
                                            <i className="fas fa-times fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-modal modal fade" id="s-sinks" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                            aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">
                                            Clean all Sinks &amp; Appliances
                                        </h2>

                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                        
                                        <img className="img-fluid rounded mb-5 loadImg" src="/images/image-loader.svg"
                                            alt="Clean all Sinks &amp; Appliances" data-src="/images/cicons/clean-sink.webp" />
                                        
                                        <p className="mb-4">
                                            We will clean all sinks, microwaves, refrigerators, ovens, etc.
                                        </p>
                                        <button className="btn btn-primary" href="#s-sinks" data-bs-dismiss="modal">
                                            <i className="fas fa-times fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-modal modal fade" id="s-bath" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                            aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">
                                            Clean all Toilets, Showers &amp; Tubs
                                        </h2>

                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                        
                                        <img className="img-fluid rounded mb-5 loadImg" src="/images/image-loader.svg" alt="ALT"
                                            data-src="/images/cicons/clean-bathroom.webp" />
                                        
                                        <p className="mb-4">
                                            We will clean &amp; sanitize all toilets, wash and remove soap
                                            scum from shower stalls and tubs.
                                        </p>
                                        <button className="btn btn-primary" href="#s-bath" data-bs-dismiss="modal">
                                            <i className="fas fa-times fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-modal modal fade" id="s-laundry" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal"
                            aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">
                                            Laundry Services
                                        </h2>

                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                        
                                        <img className="img-fluid rounded mb-5 loadImg" src="/images/image-loader.svg" alt="ALT"
                                            data-src="/images/cicons/laundry.webp" />
                                        
                                        <p className="mb-4">
                                            We also offer laundry services at an additional cost. Please inquire.
                                        </p>
                                        <button className="btn btn-primary" href="#s-laundry" data-bs-dismiss="modal">
                                            <i className="fas fa-times fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Modals;