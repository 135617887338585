function Copyright() {
    const CurrentYear = new Date().getFullYear();

    return (
        <div className="copyright py-4 text-center text-white">
            <div className="container"><small>&copy; Copyright {CurrentYear}, Simply Clean by Michell</small></div>
        </div>
    );
}

export default Copyright;