import $ from 'jquery';
import loadingOn from '../functions/loadingOn';
import loadingOff from '../functions/loadingOff';
import goTo from '../functions/goTo';
import 'jquery-mask-plugin/dist/jquery.mask.min';
import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

class BookingForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            phone: "6086072758",
            textOk: true,
            dateTime: "",
            message: ""
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);

        this.serviceId = "simplyclean";
        this.adminTemplate = "bookingAdmin";
        this.customerTemplate = "bookingCustomer";
        this.publicKey = "RFiOlNNOtR5mng6SY";
    }

    componentDidMount() {
        $("#book-phone").mask("(000) 000-0000");
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    formError(mess, id) {
        const field = $("#" + id);
        const errorDisp = $("#" + id + "-error");
        errorDisp.text(mess);
        field.addClass("is-invalid");
        goTo("#" + id);
        return;
    }

    bookFormDone() {
        const bookFormCont = $("#book-form-cont");
        const bookFormDoneCont = $("#book-form-done-cont");
        bookFormCont.hide();
        bookFormDoneCont.fadeIn();
        goTo("#book-form-dont-cont");
    }

    handleOnSubmit(event) {
        event.preventDefault();

        $(".form-control").removeClass("is-invalid");

        const t = event.target;

        if (!t.name.value.length) {
            this.formError("Please enter your name", "book-name");
            return;
        }

        if (!t.email.value.length) {
            this.formError("Please enter your email address", "book-email");
            return;
        }

        if (!this.validateEmail(t.email.value))
        {
            this.formError("Email address is invalid");
            return;
        }

        if (!t.phone.value.length) {
            this.formError("Please enter your phone number", "book-phone");
            return;
        }

        if (!t.dateTime.value.length) {
            this.formError("Please choose a date and time", "book-datetime");
            return;
        }

        if (!t.message.value.length) {
            this.formError("Please enter a message", "book-message");
            return;
        }

        loadingOn();
        emailjs.sendForm(this.serviceId, this.adminTemplate, event.target, this.publicKey)
            .then((result) => {
                loadingOff();
                if (result.text === 'OK') {
                    Swal.fire({
                        icon: "success",
                        title: "Request sent!"
                    });
                    this.bookFormDone();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops! Something went wrong."
                    });
                }

            }, (error) => {
                loadingOff();
                Swal.fire({
                    icon: "error",
                    title: "Oops! Something went wrong.",
                    text: error.text
                });
            });
    }

    render() {
        const MessageStyle = {
            height: "10rem"
        };

        const BookFormDoneStyle = {
            display: "none"
        }

        return (
            <section className="page-section" id="book-us">
                <div className="container" id="book-form-cont">

                    <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Book Us</h2>

                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xl-7">
                            <form id="booking-form" onSubmit={this.handleOnSubmit}>
                                <div className="form-floating mb-3">
                                    <input
                                        className="form-control"
                                        id="book-name"
                                        type="text"
                                        placeholder="Enter your name..."
                                        data-sb-validations="required" name="name"
                                        onChange={this.handleInputChange} />
                                    <label htmlFor="book-name">Full name</label>
                                    <div id="book-name-error" className="invalid-feedback"></div>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        className="form-control"
                                        id="book-email"
                                        type="email"
                                        placeholder="name@example.com"
                                        data-sb-validations="required,email"
                                        name="email"
                                        onChange={this.handleInputChange} />
                                    <label htmlFor="book-email">Email address</label>
                                    <div id="book-email-error" className="invalid-feedback"></div>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        className="form-control"
                                        id="book-phone"
                                        type="tel"
                                        placeholder="(123) 456-7890"
                                        data-sb-validations="required"
                                        name="phone"
                                        onChange={this.handleInputChange} />
                                    <label htmlFor="book-phone">Phone number</label>
                                    <div id="book-phone-error" className="invalid-feedback"></div>
                                </div>
                                <div className="form-floating mb-3 mt-5">
                                    <label className="form-check-label" htmlFor="book-text">I can receive texts</label>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="book-text"
                                        name="textOk"
                                        checked
                                        onChange={this.handleInputChange} />
                                    <div id="book-text-error" className="invalid-feedback"></div>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="datetime-local"
                                        className="form-control"
                                        id="book-datetime"
                                        name="dateTime"
                                        placeholder="mm/dd/yyyy --:--"
                                        onChange={this.handleInputChange} />
                                    <label htmlFor="book-datetime">When Do You Need Us?</label>
                                    <div id="book-datetime-error" className="invalid-feedback"></div>
                                </div>
                                <div className="form-floating mb-3">
                                    <textarea
                                        className="form-control"
                                        id="book-message"
                                        type="text"
                                        placeholder="Enter your message here..."
                                        style={MessageStyle}
                                        data-sb-validations="required"
                                        name="message"
                                        onChange={this.handleInputChange} ></textarea>
                                    <label htmlFor="message">Message</label>
                                    <div id="book-message-error" className="invalid-feedback"></div>
                                </div>
                                <button className="btn btn-primary btn-xl" id="submitButton" type="submit">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="container" id="book-form-done-cont" style={BookFormDoneStyle}>

                    <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Thank You!</h2>

                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    <p className="text-center">A customer service rep will be contacting you shortly.</p>
                </div>
            </section>
        );
    }
}

export default BookingForm;