import Nav from './components/Nav';
import Header from './components/Header';
import Portfolio from './components/Portfolio';
import BookingForm from './components/BookingForm';
import About from './components/About';
import Footer from './components/Footer';
import Copyright from './components/Copyright';
import Modals from './components/Modals';
import Loader from './components/Loader';

function App() {
  return (
    <div className="App">
      <Nav />
      <Header />
      <Portfolio />
      <About />
      <BookingForm />
      <Footer />
      <Copyright />
      <Modals />
      <Loader />
    </div>
  );
}

export default App;
