function About() {
    const bookNowBtnStyle = {
        "marginRight": "1em"
    };

    return (
        <section className="page-section bg-primary text-white mb-0" id="about">
            <div className="container">

                <h2 className="page-section-heading text-center text-uppercase text-white">About</h2>

                <div className="divider-custom divider-light">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                    <div className="divider-custom-line"></div>
                </div>

                <div className="row">
                    <div className="col-lg-4 ms-auto">
                        <img className="loadImg michellPic" src="/images/image-loader.svg" data-src="/images/michell.webp"
                            alt="Michell" />
                    </div>
                    <div className="col-lg-4 me-auto">
                        <p className="lead">Michell offers cleaning services for residential, commercial, new home
                            construction sites, and move-ins and -outs on a monthly, weekly, bi-weekly
                            or one-time basis. Eco-friendly cleaning supplies are available.</p>
                    </div>
                </div>

                <div className="text-center mt-4">
                    <a className="btn btn-xl btn-outline-light" href="#book-us" style={bookNowBtnStyle}>
                        <i className="fas fa-calendar me-2"></i>
                        Book Now!
                    </a>
                    <a className="btn btn-xl btn-outline-light" href="https://facebook.com/simplycleanmichell"
                        target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook me-2"></i>
                        Like Us
                    </a>
                </div>
            </div>
        </section>
    );
}

export default About;